import React, { useEffect, useState, useLayoutEffect, useContext, useRef } from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import Image from 'gatsby-plugin-sanity-image';
import Vimeo from '@u-wave/react-vimeo';
import { ThemeContext } from '../components/theme-provider';
import FadeIn from '../components/fade-in';
import PortableText from '../components/portable-text';
import HoverText from '../components/hover-text.js';
import Button from '../components/button';
import ImageButton from '../components/image-button';
import NewsItem from '../components/news-item';
import ArrowRight from '../images/svg/arrow-right.svg';
import ArrowDown from '../images/svg/arrow-down.svg';

export default function Homepage({ data, location }) {
  const [showIntro, setShowIntro] = useState(false);
  const [news, setNews] = useState([]);
  const [activeNews, setActiveNews] = useState(null);
  const [time, setTime] = useState([]);
  const [showTime, setShowTime] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(false);
  const [middleCircles, setMiddleCircles] = useState('quote');

  const theme = useContext(ThemeContext);

  const radius = 110;

  useLayoutEffect(() => {
    theme.setLogoColorDark(true);
    theme.setMenuColorDark(true);
    theme.setBackgroundColor(null);

    setTimeout(() => {
      //setShowScrollDown(true);
    }, 5000);

    setTimeout(() => {
      setShowTime(true);
      setShowIntro(1);
    }, 3000);

    setMiddleCircles([
      'address',
      'address-syd',
    ]);

    setCircle();
  }, []);

  useLayoutEffect(() => {
    setNews(data.news.nodes);
  }, [data.news.nodes]);

  /*useEffect(() => {
    const timer = setInterval(() => {
      setCircle();
    }, 60000);

    return () => clearInterval(timer);
  });*/

  function setCircle() {
    const date = new Date();
    const formattedDate = toPeriodFormat(date);

    setTime([
      [(formattedDate.hours.charAt(0) === '0') ? '' : formattedDate.hours.charAt(0)],
      [formattedDate.hours.charAt(1)],
      [formattedDate.minutes.charAt(0)],
      [formattedDate.minutes.charAt(1)],
      [formattedDate.ampm.charAt(0)],
      [formattedDate.ampm.charAt(1)],
    ]);
  }

  function toPeriodFormat(date) {
    let hours = date.getHours(),
    minutes = date.getMinutes(),

    ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? '0' + hours : hours;

    minutes = minutes < 10 ? '0' + minutes : minutes;

    return {
      hours: hours.toString(),
      minutes: minutes.toString(),
      ampm: ampm,
    };
  }

  return (
    <div>
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          height: 100vh;
          z-index: -1;
        `}
      >
        {data.homepage?.introVideo && <Vimeo
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            width: 177.77777778vh;
            height: 56.25vw;
            min-width: 100%;
            min-height: 100%;
            transform: translate(-50%, -50%);
            opacity: ${(showIntro) ? 1 : 0};
            transition: opacity 0.6s var(--curve);
          `}
          video={data.homepage?.introVideo}
          onPlay={(e) => { setShowIntro(1); setShowTime(true) }}
          start={(data.homepage?.videoStartTimes.length && location.state?.random) ? data.homepage?.videoStartTimes[Math.floor(Math.random() * data.homepage?.videoStartTimes.length)] : 0}
          background
          responsive
          loop
        />}
        <div
          className="type--mono"
          css={css`
            position: absolute;
            right: var(--margin);
            bottom: var(--margin);
            width: 260px;
            height: 260px;
            opacity: ${(showTime) ? 1 : 0};
            overflow: hidden;
            transition: opacity 0.6s var(--curve);

            img, svg {
              filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.1));
            }

            @media (max-width: 1024px) {
              right: calc(50%);
              bottom: calc(var(--margin) * 12);
              transform: translateX(50%);
            }
          `}
        >
          <img
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              animation: spin 40s linear infinite;
            `}
            src="/images/circle-outer.svg" />
          <img
            css={css`
              position: absolute;
              top: 0;
              left: 12.1%;
              width: 76.7%;
              height: 100%;
              animation: spin 30s linear infinite reverse;
            `}
            src={`/images/circle-${middleCircles[Math.floor(Math.random() * middleCircles.length)]}.svg`} />
          <div
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              animation: spin 20s linear infinite;
            `}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="244.23" height="244.23" viewBox="0 0 244.23 244.23">
              <defs>
                <style>
                  {
                    `.a {
                      fill: #fff;
                      opacity: 0;
                    }

                    .b {
                      font-size: 18px;
                      font-family: 'Quadrant Mono', monospaced;
                    }`
                  }
                </style>
              </defs>
              <rect className="a" width="244.23" height="244.23"/>
              <text className="b" transform="matrix(-0.3, -0.95, 0.95, -0.3, 81.91, 140.29)">{time[0]}</text>
              <text className="b" transform="matrix(-0.03, -1, 1, -0.03, 78.51, 129.01)">{time[1]}</text>
              <text className="b" transform="translate(78.24 117.21) rotate(-76.51)">:</text>
              <text className="b" transform="matrix(0.49, -0.87, 0.87, 0.49, 81.12, 105.79)">{time[2]}</text>
              <text className="b" transform="matrix(0.7, -0.71, 0.71, 0.7, 86.97, 95.55)">{time[3]}</text>
              <text className="b" transform="matrix(0.87, -0.5, 0.5, 0.87, 95.33, 87.24)">{time[4]}</text>
              <text className="b" transform="matrix(0.97, -0.25, 0.25, 0.97, 105.6, 81.46)">{time[5]}</text>
            </svg>
          </div>
        </div>
        <div
          className="type--mono"
          css={css`
            position: absolute;
            bottom: var(--margin);
            width: 100%;
            text-align: center;
            cursor: pointer;
            transition: opacity 0.6s var(--curve);

            @media (max-width: 900px) {
              bottom: calc(7 * var(--margin));
            }

            &:hover {
              opacity: 0.6;
            }

            svg path {
              fill: var(--black);
            }
          `}
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight,
              left: 0,
              behavior: 'smooth'
            });
          }}
        >
          <div
            className="type--small-mono"
            css={css`
              margin-bottom: 1.5rem;
              opacity: ${(showScrollDown) ? 1 : 0};
              transition: opacity 0.6s var(--curve);
            `}
          >
            Scroll Down
          </div>
          <ArrowDown />
        </div>
      </div>
      <div
        css={css`
          background-color: var(--white);
          margin-top: 100vh;
          padding-bottom: var(--footer-margin);
        `}
      >
        <div
          css={css`
            padding: 0 var(--margin);
            padding-top: 15rem;
            margin-bottom: 11rem;

            @media (max-width: 900px) {
              padding-top: 5rem;
              margin-bottom: 5rem;
            }
          `}
        >
          <div
            className="type--large"
            css={css`
              p {
                margin-bottom: 1.5rem;
              }
            `}
          >
            {data.homepage?._rawProjectsText &&
              <HoverText text={data.homepage._rawProjectsText} />
            }
          </div>
          <Button
            css={css`
              position: relative;
              margin-top: 5rem;
              margin-bottom: 2rem;
              z-index: 2;

              @media (max-width: 900px) {
                margin-top: 2rem;
                margin-bottom: 0;
              }
            `}
            external={false}
            url="/projects"
            text="All Projects"
            arrow={<ArrowRight />}
          />
        </div>
        <FadeIn
          css={css`
            position: relative;
            line-height: 0;
            margin-bottom: 11.5rem;

            @media (max-width: 900px) {
              margin-bottom: 5rem;
            }
          `}
        >
          {data.homepage?.approachImage && <Image
            {...data.homepage.approachImage}
            width={1980}
            style={{
              width: '100%',
            }}
            css={css`
              display: block;

              @media (max-width: 900px) {
                display: none;
              }
            `}
          />}
          {data.homepage?.approachImage && <Image
            {...data.homepage.approachImage}
            width={800}
            height={950}
            style={{
              width: '100%',
            }}
            css={css`
              display: none;

              @media (max-width: 900px) {
                display: block;
              }
            `}
          />}
          <Button
            css={css`
              position: absolute;
              bottom: var(--margin);
              left: var(--margin);
              background-color: var(--white);
            `}
            external={false}
            url="/approach"
            text="Our Approach"
            arrow={<ArrowRight />}
          />
        </FadeIn>
        <FadeIn
          css={css`
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: var(--gutter);
            padding: 0 var(--margin);
            margin-bottom: 11.5rem;

            @media (max-width: 900px) {
              display: block;
              margin-bottom: 5rem;
            }
          `}
        >
          <div
            css={css`
              grid-column: span 5;
            `}
          >
            <div
              className="type--large"
              css={css`
                quotes: "“" "”";
                margin-bottom: 6rem;
                font-feature-settings: 'ss03' on;

                p:first-of-type::before {
                  content: open-quote;
                }

                p:last-of-type::after {
                  content: close-quote;
                }

                @media (max-width: 900px) {
                  margin-bottom: 2rem;
                }
              `}
            >
              {data.homepage?._rawPeopleQuote &&
                <PortableText blocks={data.homepage._rawPeopleQuote} />
              }
            </div>
            <div
              className="type--medium"
              css={css`
                white-space: pre-line;

                @media (max-width: 900px) {
                  font-size: 2rem;
                  margin-bottom: 5rem;
                }
              `}
            >
              {data.homepage?.peopleQuoteAuthor}
            </div>
          </div>
          <div
            css={css`
              grid-column-start: 7;
              grid-column-end: span 6;
              line-height: 0;
            `}
          >
            {data.homepage?.peopleImage && <ImageButton
              image={data.homepage.peopleImage}
              external={false}
              url="/people"
              text="Our People"
              arrow={<ArrowRight />}
            />}
          </div>
        </FadeIn>
        <FadeIn
          css={css`
            padding: 3rem var(--margin) 9rem;
            border-top: 1px solid var(--black);
            border-bottom: 1px solid var(--black);

            @media (max-width: 900px) {
              padding: 3rem var(--margin) 5rem;
            }
          `}
        >
          <div
            css={css`
              margin-bottom: 6rem;

              @media (max-width: 900px) {
                margin-bottom: 5rem;
              }
            `}
            className="type--large"
          >Latest News</div>
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(12, 1fr);
              gap: var(--gutter);

              @media (max-width: 900px) {
                display: flex;
                flex-direction: column;
              }
            `}
          >
            <div
              css={css`
                grid-column: span 5;

                @media (max-width: 900px) {
                  order: 2;
                }
              `}
            >
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1;
                  position: sticky;
                  top: var(--gutter);
                `}
              >
                <div
                  css={css`
                    grid-row-start: 1;
                    grid-column-start: 1;
                    grid-column-end: 1;
                    line-height: 0;
                    opacity: ${(!activeNews?.image) ? 1 : 0};
                    z-index: ${(!activeNews?.image) ? 1 : 0};
                    transition: opacity 0.6s var(--curve);
                  `}
                >
                  {data.homepage?.newsImage && <ImageButton
                    image={data.homepage.newsImage}
                    external={false}
                    text="See All News"
                    arrow={<ArrowRight />}
                    onClick={() => theme.setShowNews(!theme.showNews)}
                    onKeyPress={(e) => {
                      e.persist();

                      if (e.key === 'Enter')
                        theme.setShowNews(!theme.showNews);
                    }}
                  />}
                </div>
                {news.length > 0 && news
                  .filter((item) => item.image)
                  .map((item, i) => (
                    <div
                      key={i}
                      css={css`
                        grid-row-start: 1;
                        grid-column-start: 1;
                        grid-column-end: 1;
                        line-height: 0;
                        opacity: ${(item === activeNews) ? 1 : 0};
                        z-index: ${(item === activeNews) ? 1 : 0};
                        transition: opacity 0.6s var(--curve);
                      `}
                    >
                      {item.image && <ImageButton
                        image={item.image}
                        external={false}
                        text="See All News"
                        arrow={<ArrowRight />}
                        onClick={() => theme.setShowNews(!theme.showNews)}
                        onKeyPress={(e) => {
                          e.persist();

                          if (e.key === 'Enter')
                            theme.setShowNews(!theme.showNews);
                        }}
                      />}
                    </div>
                  ))
                }
              </div>
            </div>
            <div
              css={css`
                grid-column-start: 7;
                grid-column-end: span 6;
              `}
            >
            {news.length > 0 && news
              .map((item) => (
                <NewsItem
                  key={item.id}
                  news={item}
                  onOpen={() => {
                    setActiveNews((item === activeNews) ? null : item);
                  }}
                />
              ))
            }
            </div>
          </div>
        </FadeIn>
        <FadeIn
          css={css`
            padding: 0 var(--margin);
            padding-top: 3rem;
          `}
        >
          <div
            css={css`
              margin-bottom: 11.5rem;

              @media (max-width: 900px) {
                margin-bottom: 5rem;
              }
            `}
            className="type--large"
          >Contact</div>
          <div className="type--large">
            {data.homepage?._rawContactText &&
              <PortableText blocks={data.homepage._rawContactText} />
            }
          </div>
          <Button
            css={css`
              margin-top: 7rem;

              @media (max-width: 900px) {
                margin-top: 5rem;
              }
            `}
            external={false}
            url="/contact"
            text="Get in Touch"
            arrow={<ArrowRight />}
          />
        </FadeIn>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    homepage: sanityHomepage(_id: {regex: "/(drafts\\\\.)?homepage/"}) {
      introVideo
      videoStartTimes
      _rawProjectsText(resolveReferences: {maxDepth: 10})
      approachImage {
        ...Image
      }
      _rawPeopleQuote
      peopleQuoteAuthor
      peopleImage {
        ...Image
      }
      newsImage {
        ...Image
      }
      _rawContactText
    }
    news: allSanityNews(limit: 5, sort: {fields: date, order: DESC}) {
      nodes {
        id
        date
        title
        _rawText
        image {
          ...ImageWithPreview
        }
      }
    }
    newsTags: allSanityNewsTag(sort: {fields: title, order: ASC}) {
      nodes {
        id
        title
      }
    }
  }
`
